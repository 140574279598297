const cookieKeys = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  ZENDESK_AUTH_TOKEN: 'ZENDESK_AUTH_TOKEN',
}

const colors = {
  NODAFI_BLUE: '#00406b',
  MONEY_GREEN: '#118c4f',
  DISABLED_GRAY: '#d3d3d3',
}

const SUPPORT_EMAIL = 'hello@nodafi.com'
const APP_STORE_URL = 'https://apps.apple.com/us/app/nodafi/id1399122213'
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.nodafi.app'

// constants
const PROTOCOL = process.env.REACT_APP_SERVER_URL?.includes('localhost') ? '' : 's' // 's' || ''
const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? 'api.nodafi.com/graphql' // 'localhost:4000' or api.nodafi-staging.com
const BASE_SERVER_URL = SERVER_URL.replace('/graphql', '')
const GRAPHQL_URI = `http${PROTOCOL}://${SERVER_URL}`

// TODO: Expose these in GraphQL API
export enum StandardResourceIdentifiers {
  TICKET = 'TICKET',
  USER = 'USER',
  GROUP = 'GROUP',
  SPACE = 'SPACE',
  LOCATION = 'LOCATION',
  SURVEY = 'SURVEY',
  PART = 'PART',
  ASSET = 'ASSET',
  COST_ENTRY = 'COST_ENTRY',
  EXTERNAL_ORGANIZATION = 'EXTERNAL_ORGANIZATION',
  PROCEDURE = 'PROCEDURE',
}

const Filters = {
  NONE: 'None',
  OR: 'OR',
}

const BooleanStringValues = {
  YES: 'Yes',
  NO: 'No',
}

const ApiWaitTimeForDebouncedSearch = 500
const SpacePinInitialVal = 1001

const FilterConstants = {
  Tickets: {
    ID: 'id',
    TITLE: 'title',
    CREATEDAT: 'createdAt',
    STATUS: 'status',
    PRIORITY: 'priority',
    ASSIGNEE: 'assignee',
    ASSIGNEDGROUP: 'assignedGroup',
    COMPLETEDAT: 'completedAt',
    COSTENTRIESDATA: 'costEntriesData',
    COSTENTRIESTIMELOGGEDHOURS: 'costEntriesTimeLoggedHours',
    COSTENTRIESTOTALCOST: 'costEntriesTotalCost',
    LOCATION: 'location',
    SPACE: 'space',
  },
}
type FilterConstantsBoolean = {
  [K in keyof typeof FilterConstants]: {
    [P in keyof (typeof FilterConstants)[K]]?: boolean
  }
}

type SkipTicketsTableFields = FilterConstantsBoolean['Tickets']

// values are in milliseconds.
const ToastAutoCloseTime = {
  fiveSeconds: 5000,
  tenSeconds: 10000,
  fifteenSeconds: 15000,
}

const fileUploadMaxSize = 15 // in MB

// file upload error messages.
const fileUploadErrorMessages = {
  MAX_FILES_LIMIT_EXCEEDED: 'Can not upload more than 5 files',
  MAX_FILE_SIZE_LIMIT_EXCEEDED: `Max file size allowed is ${fileUploadMaxSize} mb`,
}

const TicketStatusCategories = {
  NOT_STARTED: 'NOT_STARTED',
  ACTIVE: 'ACTIVE',
  DONE: 'DONE',
}

const FIELD_REQUIRED_MESSAGE = 'This is required.'

type ResponseError = {
  title: string
  messages: string[]
}

const ticketFieldKeys = {
  id: 'id',
  flags: 'flags',
  sequentialId: 'sequentialId',
  title: 'title',
  description: 'description',
  message: 'message',
  image: 'image',
  location: 'location',
  assets: 'assets',
  space: 'space',
  issue: 'issue',
  files: 'files',
  assignedGroup: 'assignedGroup',
  assignee: 'assignee',
  dueDate: 'dueDate',
  startDate: 'startDate',
  calendarEvent: 'calendarEvent',
  vendors: 'vendors',
  customers: 'customers',
  watchers: 'watchers',
  priority: 'priority',
  status: 'status',
  costEntries: 'costEntries',
  part: 'part',
  isSubscribedToTicketUpdates: 'isSubscribedToTicketUpdates',
  requester: 'requester',
  fieldEntries: 'fieldEntries',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  completedAt: 'completedAt',
  procedure: 'procedure',
} as const

const nestedTicketFieldKeys = {
  requesterComment: 'requesterComment',
  locationName: 'locationName',
  spaceName: 'spaceName',
  spaceDetail: 'spaceDetail',
  assetNames: 'assetNames',
  vendorName: 'vendorName',
  customerName: 'customerName',
  issueName: 'issueName',
  costEntriesTotalCost: 'costEntriesTotalCost',
  costEntriesTimeLoggedHours: 'costEntriesTimeLoggedHours',
  completedOn: 'completedOn',
  latestComment: 'latestComment',
} as const

const ANONYMOUS_USER = 'Anonymous'

const DATE_CONSTANTS = {
  MIN_DATE: new Date(0),
  MAX_DATE: new Date('9999-12-31 00:00:00.000'),
}

enum LOOKUP_OPERATION {
  SEARCH = 'SEARCH',
  GET_MORE = 'GET_MORE',
}

const REACT_INFINITE_SCROLL_HOOK = {
  ID: 'react-infinite-scroll-hook',
}

const STYLE_WIDTHS = {
  FULL_WIDTH: '100%',
  HALF_WIDTH: '50%',
  THREE_QUARTER_WIDTH: '75%',
  QUARTER_WIDTH: '25%',
}

const CustomDataFields = {
  PICKER_OPTIONS: 'pickerOptions',
}
const TEST_ORGANIZATIONS = ['dev', 'demo']

const CUSTOM_REQUEST_HEADERS = {
  SUBDOMAIN_HEADER_KEY: 'x-nodafi-account-subdomain',
  ACCOUNT_ID_HEADER_KEY: 'x-nodafi-account-id',
}

export {
  PROTOCOL,
  SERVER_URL,
  BASE_SERVER_URL,
  GRAPHQL_URI,
  cookieKeys,
  colors,
  SUPPORT_EMAIL,
  APP_STORE_URL,
  GOOGLE_PLAY_URL,
  FIELD_REQUIRED_MESSAGE,
  DATE_CONSTANTS,
  Filters,
  ApiWaitTimeForDebouncedSearch,
  FilterConstants,
  ToastAutoCloseTime,
  SpacePinInitialVal,
  fileUploadErrorMessages,
  TicketStatusCategories,
  BooleanStringValues,
  ticketFieldKeys,
  nestedTicketFieldKeys,
  ANONYMOUS_USER,
  REACT_INFINITE_SCROLL_HOOK,
  LOOKUP_OPERATION,
  STYLE_WIDTHS,
  fileUploadMaxSize,
  CustomDataFields,
  TEST_ORGANIZATIONS,
  CUSTOM_REQUEST_HEADERS,
}
export type { ResponseError, FilterConstantsBoolean, SkipTicketsTableFields }
